import CRMContactView from "./CRMContact";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { crm } from "../../store/cabinetApi/crm";
import { PAGE_URL } from "../../constants";
import { selectDenormalizedData } from "../../store/crm/selectors";
import {
  ContactDenorm, ContactOrgDenorm, Leader, LeaderRelationshipDenorm, RootState, ThunkDispatchType
} from "../../store";
import { Contact, ContactField } from "../../store/cabinetApi/generated/crm";
import { skipToken } from "@reduxjs/toolkit/query";
import { useDispatch, useSelector } from "react-redux";
import { analytics } from "../../store/cabinetApi/analytics";
import { fetchLeaders } from "../../store/leaders/actions";
import { useMountEffect } from "../../utils/hooks";
import { createSearchParams } from "react-router-dom";


type RouteParams = { contactId: string; };

const CRMContactContainer = () => {
  const params = useParams<RouteParams>();
  const contactId = params.contactId ? Number(params.contactId) : undefined;
  const [orgString, setOrgString] = useState('');

  const userLicenseId = useSelector((root: RootState) => root.auth.user?.active_license.id);
  const leaders = useSelector((root: RootState) => root.leaders.leaders);

  const { data: contactData, isLoading: loadingContact } = crm.useAppCrmContactsRetrieveQuery(contactId ?? skipToken);
  const { data: org } = crm.useAppCrmCompaniesRetrieveQuery(contactData?.company ?? skipToken);
  const { data: orgList } = crm.useAppCrmCompaniesListQuery({ 
    limit: 20, nameIcontains: orgString, orderBy: ["name"], hiddenDateIsnull: true
  });
  const { data: catData } = crm.useAppCrmContactCategoriesListQuery({ limit: 1000 });
  const { data: typeData } = crm.useAppCrmContactTypesListQuery({ limit: 1000 });
  const { data: fieldTemplatesData } = crm.useAppCrmContactFieldTemplatesListQuery({ limit: 1000 });
  const { data: taskData } = crm.useAppCrmContactTasksListQuery(
    contactId != null ? { limit: 10, contacts: [contactId] } : skipToken
  );
  const { data: notesData } = crm.useAppCrmContactNotesListQuery(
    contactId != null ? { limit: 10, contacts: [contactId] } : skipToken
  );
  const { data: eventsData, isLoading: isEventsLoading } = analytics.useAppAnalyticsAnalyticsEventsListQuery(
    contactId != null
      ? { limit: 10, attendees: [contactId], orderBy: ["-start_date"], /*distinct: 'event_unique_id'*/ }
      : skipToken
  );

  const { data: relationshipData } = crm.useAppCrmLeaderRelationshipsListQuery(contactId != null
    ? { limit: 50, offset: 0, contact: contactId }
    : skipToken
  );

  const relationships = relationshipData && selectDenormalizedData(relationshipData) as LeaderRelationshipDenorm[];

  const relationshipLeaders = useMemo(() => (
    relationships?.map(r => r.leader).filter((r): r is Leader => !!r) || []
  ), [relationships]);

  const [updateContact] = crm.useAppCrmContactsPartialUpdateMutation();
  const [addNote] = crm.useAppCrmContactNotesCreateMutation();
  const [updateNote] = crm.useAppCrmContactNotesPartialUpdateMutation();
  const [deleteNote] = crm.useAppCrmContactNotesDestroyMutation();
  const [addTask] = crm.useAppCrmContactTasksCreateMutation();
  const [updateTask] = crm.useAppCrmContactTasksPartialUpdateMutation();
  const [deleteTask] = crm.useAppCrmContactTasksDestroyMutation();
  // const [updateRelationship] = crm.useAppCrmLeaderRelationshipsPartialUpdateMutation();
  const [createRelationship] = crm.useAppCrmLeaderRelationshipsCreateMutation();
  const [deleteRelationship] = crm.useAppCrmLeaderRelationshipsDestroyMutation();


  const catList = catData?.data || [];
  const typeList = typeData?.data || [];

  const contactType = typeList.find(t => t.id === contactData?.contact_type);
  const contactCategory = catList.find(c => c.id === contactData?.contact_category);

  const contact = selectDenormalizedData(
    { data: contactData },
    {
      ...(org && { company: org }),
      ...(contactType && { contact_type: contactType }),
      ...(contactCategory && { contact_category: contactCategory }),
    }
  ) as ContactDenorm | undefined;

  const orgs = orgList && selectDenormalizedData(orgList) as ContactOrgDenorm[];

  const taskList = taskData?.data || [];
  const notesList = notesData?.data || [];
  const eventsList = eventsData?.data || [];

  const dispatch = useDispatch<ThunkDispatchType>();
  const navigate = useNavigate();

  const handleNavigateToContacts = () => (
    navigate(PAGE_URL.CRM_PEOPLE)
  );

  const handleNavigateToDataView = (category: string) => (
    navigate({
      pathname: PAGE_URL.CRM_SEARCH,
      search: createSearchParams({ entities: [category] }).toString(),
    })
  );

  const handleNavigateToContactOrg = contact?.company?.id
    ? (() => navigate(`${PAGE_URL.CRM_COMPANIES}/${contact.company?.id}`))
    : undefined;

  const handleUpdateContactBasicInfo = <T extends keyof Contact>(field: T, value: Contact[T]) => {
    if (contact?.id) {
      updateContact({ id: contact.id, patchedContact: { [field]: value } });
    }
  };

  const handleUpdateContactField = <T extends keyof Required<Contact>['fields']>(
    templateId: T, value: ContactField
  ) => {
    if (contact?.id) {
      updateContact({
        id: contact.id,
        patchedContact: { fields: { ...contact.fields, [templateId]: value } }
      });
    }
  };

  const handleAddTask = async ({ title }: { title: string }) => {
    if (!contact || !userLicenseId) return;

    await addTask({
      id: -1,
      title: title,
      updated_at: '',
      contacts: [contact.id],
      assignee: userLicenseId,
      teammates: [],
      company: [],
      allowed_users: [],
    });
  };

  const handleUpdateRelationships = async (leaderIds: Leader['id'][]) => {
    if (!contactId) return;

    // const existingRelationships = leaderIds.map(lId => relationships?.find(r => r.leader?.id === lId))
    //   .filter((r): r is LeaderRelationshipDenorm => !!r);
    const relationshipsToRemove = (relationships || []).filter(r => !leaderIds.find(lId => lId === r.leader?.id));
    const newRelationshipLeaderIds = leaderIds.filter(lId => !relationshipLeaders.find(l => l.id === lId));

    const removePromises = relationshipsToRemove.map(r => deleteRelationship(r.id));
    const addPromises = newRelationshipLeaderIds.map(lId => createRelationship({
      id: -1, leader: lId, contact: contactId,
    }));

    await Promise.all(removePromises);
    await Promise.all(addPromises);
  };

  useMountEffect(() => {
    dispatch(fetchLeaders);
  });

  return (
    <CRMContactView
      contact={contact || null}
      onNavigateToContacts={handleNavigateToContacts}
      onNavigateToDataView={handleNavigateToDataView}
      onSaveBasicInfo={handleUpdateContactBasicInfo}
      onSaveField={handleUpdateContactField}
      orgOptions={orgs || []}
      categoryOptions={catList}
      typeOptions={typeList}
      fieldTemplates={fieldTemplatesData?.data || []}
      tasks={taskList}
      notes={notesList}
      events={eventsList}
      onAddNote={addNote}
      onUpdateNote={updateNote}
      onDeleteNote={deleteNote}
      onAddTask={handleAddTask}
      onUpdateTask={updateTask}
      onDeleteTask={deleteTask}
      loading={loadingContact}
      loadingEvents={isEventsLoading}
      onSetOrgString={setOrgString}
      relationshipLeaders={relationshipLeaders}
      leaderOptions={leaders}
      onUpdateRelationships={handleUpdateRelationships}
      onNavigateToContactOrg={handleNavigateToContactOrg}
    />
  );
};

export default CRMContactContainer;
